import React, { useState, useRef, useEffect } from 'react';
import profile from '../images/acesmog_logo2.png'
import become_famous from '../images/become_famous_liz_sanchez.png'
// import background_img from "../assets/imgs/home.jpeg"
import background_img from "../images/3.png"
// import background_img from "../images/bg_data_1.png"
import axios from 'axios';

const Home = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [name, setName] = useState('');
  const [pnumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const avatarRef = useRef(null); 
  const [showBookList, setShowBookList] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const services = [
    'Facebook',
    'Instagram',
    'YouTube',
    'TikTok',
    'LinkedIn',
    'Pinterest',
    'X (formerly Twitter)',
    'Others',
  ]
  const handleServiceClick = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter((s) => s !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  useEffect(() => {
    const handleTouchStart = (e) => {
      const startY = e.touches[0].clientY;
      const threshold = 250; 
      let pullDownStarted = false;

      const handleTouchMove = (moveEvent) => {
        const currentY = moveEvent.touches[0].clientY;
        const deltaY = currentY - startY;

        if (deltaY > threshold && !pullDownStarted) {
          pullDownStarted = true;
          window.location.reload(); // Refresh the page
        }
      };

      const handleTouchEnd = () => {
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd);
      };

      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleTouchEnd);
    };

    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width threshold as needed for your definition of "mobile"
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Validate email using a regular expression
     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
     if (!emailRegex.test(email)) {
       setIsValidEmail(false);
       return;
     }

     if(message.length < 20 || pnumber.length < 10){
      return;
     }
 
     setIsValidEmail(true);

    const emailData = {
      name,
      email,
      message: "Services: " + selectedServices.join(", ") + "\r\n" + message  + "\r\n" + "Phone number: " + pnumber
    }

     try {
      const response = await fetch('https://acesogrowth.com/.inc/send-email.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData),
        });

        if (response.ok) {
          setShowDialog(true);
          setName('');
          setEmail('');
          setMessage('');
          setPhoneNumber('');
        } else {
          alert('Somethign is missing. Try again');
        }
      } catch (error) {
        console.log(error)
        alert('Something is missing. Try again.');
      }

      // try {
      //   const response = await axios.post('https://acesogrowth.com:5000/log', { email });
      //   console.log(response.data);
      // } catch (error) {
      //   console.error('There was an error logging the data!', error);
      // }
    
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const scrollToAvatar = () => {
    if (avatarRef.current) {
      avatarRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const toggleBookList = () => {
    setShowBookList(!showBookList);  
  };

  return (
    <div className="h-screen flex items-center justify-center" 
    style={{
      backgroundImage: `url(${background_img})`,
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center'
      }}>
      <div className="bg-black w-full bg-opacity-70 p-8 shadow-lg h-screen overflow-y-scroll no-scrollbar">
      
        <div className="text-center mb-5">
          <img
            src={profile}
            alt="Accelerate Social Media Organic Growth Logo"
            style={{width: '150px'}}
            className="rounded-full mx-auto mb-4"
          />
          <h1 className="text-2xl font-bold text-gray-100">Accelerate Social Media Organic Growth</h1>
          {/* <p className="text-gray-200 italic">Helping you reach more valuable customers.</p> */}
          <p className="mt-1 text-black bg-white italic px-1 py-2 rounded">
            Empowering your brand to connect with more valuable customers.
          </p>
        </div>

        {/* <div className="mt-8">
          <img
            src={become_famous}
            alt="How To Become Famous and Increase Sales"
            className="w-full h-auto rounded-lg shadow-lg animate-banner"
          />
        </div> */}

        <div className="relative mt-8">
            <img
              src={become_famous}
              alt="How To Become Famous and Increase Sales"
              className="w-full h-auto rounded-lg shadow-lg animate-spin-banner"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white text-center">WE CAN MAKE IT HAPPEN!</h2>
            </div>
          </div>

          {/* <style jsx>{`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .animate-spin-banner {
    animation: spin 4s linear infinite;
  }
`}</style> */}

        <style jsx>{`
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(100px); }
    100% { opacity: 1; transform: translateY(0); }
  }

  .animate-banner {
    animation: fadeIn 4s ease-in-out;
  }
`}</style>

        <div className="p-8 bg-white rounded-lg shadow-lg max-w-3xl mx-auto mt-8">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4"
          style={{color: "#6b806f"}}
          >How To Become Famous</h1>
          <p className="text-lg">
            <span className="text-5xl font-bold float-left mr-2">I</span> nfluencer marketing is a powerful way to grow your brand. For every dollar spent, you can earn $5.20, making it a great investment. Micro-influencers, who have fewer than 25,000 followers, are especially effective with a high engagement rate of 7% compared to 1.7% for larger influencers.
            People trust recommendations from others, with 92% trusting them over brand messages. This trust leads to better engagement, and 60% of marketers say influencer content performs better than content created by brands. Using influencers can also save brands 20-40% on digital marketing costs compared to traditional advertising.
          </p>
          <p className="text-lg mt-4">
          Influencers do more than save money. About 71% of marketers say influencers can set trends in their target markets. Influencer recommendations are important, as 49% of consumers rely on them to make buying decisions. Instagram is the top platform for influencer marketing, used by 79% of brands.
          </p>

          <p className="text-lg mt-4">
          Efficiency is another big benefit. Using influencer marketing platforms can cut campaign management time by up to 30%, allowing brands to focus on other important tasks. Influencer campaigns reach 2.2 times more of the target audience than other digital marketing methods, showing their wide reach and effectiveness.
          Influencer endorsements build brand loyalty, with 67% of social media users following brands promoted by their favorite influencers. Additionally, 86% of marketers use influencer-generated content on their own channels, which boosts authenticity and engagement.
          </p>

          <p className="text-lg mt-4">
          The impact on sales is impressive too. Brands working with influencers see a 10% higher conversion rate. Posts with influencers get 11 times more engagement than regular brand posts, showing the strong connection between influencers and their followers.
          Influencer marketing is also cost-effective, lowering the cost per acquisition by up to 33% compared to traditional methods. By using influencers, brands can shine on social media, save time and money, and achieve great engagement and sales. <b>Your brand can thrive by partnering with our dedicated creators!</b>
          </p>
        </div>

      
        <div className="mt-10">
          <p className='bg-green-500 rounded'>
          <h2 className="text-md font-semibold py-2 mb-4 text-white text-center"
          style={{textTransform: "uppercase"}}
          >Platform Preferences</h2>
          </p>
          <div className="grid grid-cols-2 gap-4 bg-green-100 p-8">
            {services.map((service, index) => (
              <button
                key={index}
                className={`${
                  selectedServices.includes(service)
                    ? 'bg-green-600 hover:bg-green-600'
                    : 'bg-black hover:bg-green-500'
                } 
                text-white text-xs md:text-lg py-2 px-4 rounded-lg focus:outline-none`}
                onClick={() => handleServiceClick(service)}
              >
                {service}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-10 h-4/6">
          <p className='bg-green-500 rounded'>
          <h2 className="text-md py-2 font-semibold mb-4 text-white text-center" 
          style={{textTransform: "uppercase"}}
          >Join our community waitlist</h2>
          </p>
          
          <form onSubmit={handleSubmit} onFocus={scrollToAvatar} className='bg-green-100 p-8'>
            <p className='text-black p-1 mb-3'>Sign up for our community waitlist now and stay connected with us. Simply fill out the form to secure your spot and be part of something amazing. </p>
            <div className="mb-4">
              <input
                type="text"
                id="name"
                placeholder='Name'
                className="w-full border rounded-md py-2 px-3 focus:outline-none focus:none focus:border-blue-300"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
               <input
                type="email"
                id="email"
                placeholder='Your Best Email to contact you'
                className={`w-full border rounded-md py-2 px-3 focus:outline-none focus:none focus:border-blue-300 ${
                  !isValidEmail ? 'border-red-500' : ''
                }`}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsValidEmail(true); 
                }}
                required
              />
            </div>

            <div className="mb-4">
               <input
                type="text"
                id="pnumber"
                placeholder='Phone Number'
                className="w-full border resize-none rounded-md py-2 px-3 focus:outline-none focus:none focus:border-blue-300"
                value={pnumber}
                minLength={10}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            {pnumber.length > 0 && pnumber.length < 10 && (
              <p className="p-1 rounded mt-2" style={{ color: 'white', background: '#431905' }}>Phone Number must be 10 or more digits.</p>
            )}
            </div>

            <div className="mb-4">
              <textarea
                id="message"
                rows="4"
                placeholder='Tell us more about you. What industry do you work in, and what specific services are you interested in?'
                className="w-full border resize-none rounded-md py-2 px-3 focus:outline-none focus:none focus:border-blue-300"
                value={message}
                minLength={20}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
              {message.length > 0 && message.length < 20 && (
            <p className="p-1 rounded" style={{ color: 'white', background: '#431905' }}>Message must be at least 20 characters long.</p>
           )}
            </div>
            <button
              type="submit"
              className="w-50 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg focus:outline-none mb-6"
            >
              GET STARTED
            </button>
          </form>

          <footer className="bg-black text-white py-5 text-center w-full">
           
            <span className="ml-2">Contact: <a href="tel:+12133525916" className="text-blue-400 hover:text-blue-600">+1 (213) 352-5916</a></span> &nbsp;  {!isMobile && <span>|</span>} &nbsp; Email:
            <a href="mailto: social@acesogrowth.com" className="text-blue-400 hover:text-blue-600 ml-2">
              social@acesogrowth.com
            </a> <br/><br/>
            &copy; {new Date().getFullYear()} Accelerate Social Media Organic Growth. All rights reserved.<br/>
          </footer>
          {isMobile && <div className="w-full" style={{ height: '60px' }}></div>}
        </div>
      </div>

      {showDialog && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg shadow-lg text-center">
          <p className="text-2xl font-bold mb-4">Thank You!</p>
          <p className="text-gray-700">We are currently accepting a limited number of requests. We will review your submission and contact you if it meets our criteria.</p>
          <button
            onClick={closeDialog}
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg mt-4 focus:outline-none"
          >
            Close
          </button>
        </div>
      </div>
    )}
    </div>
    
    
  );
};

export default Home;
